import React, { Component, Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { setDisplayName } from 'recompose'
import DatePickerComponent from 'react-datepicker'
import moment, { Moment } from 'moment'
import MaskedInput from 'react-text-mask'
import withTranslation from '../../../globals/components/withTranslation'

import 'moment/locale/ro'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

interface IDatePickerProps {
  currentLanguage?: string
  initialValue?: Date
  maxDate?: Date | Moment
  minDate?: Date | Moment
  input?: any
  onChangeDate?: (dateRegistered: Date | Moment) => void
  setToLocaleDateString?: boolean
  disableFuture?: boolean
}

interface IDatePickerState {
  selectedDate: Date | Moment
}

const withFormField = Component => {
  return setDisplayName('withFormField')(props => {
    const { touched, error } = props.meta
    return (
      <Fragment>
        <Component {...props} />
        {touched && error && <div className="validation-message">{error}</div>}
      </Fragment>
    )
  })
}

class DatePicker extends Component<IDatePickerProps, IDatePickerState> {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: null,
    }
  }

  setSelectedDate = (date: Date) => {
    const { input, onChangeDate, maxDate, minDate } = this.props
    // check if date is not bigger/smaller than max/min values
    const isAfter = maxDate && date ? moment(date).isAfter(maxDate) : false
    const isBefore = minDate && date ? moment(date).isBefore(minDate) : false
    if (isAfter || isBefore) return

    this.setState({ selectedDate: date ? moment(date) : date })
    input?.onChange(date)
    if (onChangeDate) {
      onChangeDate(moment(date))
    }
  }

  componentDidMount() {
    const { currentLanguage, initialValue, input } = this.props
    const language = currentLanguage === 'en' ? 'en-gb' : currentLanguage
    moment.locale(language)

    if (initialValue) {
      this.setSelectedDate(initialValue)
    } else if (input?.value) {
      this.setSelectedDate(new Date(input.value))
    }
  }

  componentDidUpdate(prevProps) {
    const { input } = this.props
    // explicitely clean if UI did not affect after resetting input.value with form 'reset'
    if (input) {
      if (isEmpty(input.value) && !isNil(this.state.selectedDate)) {
        this.setSelectedDate(null)
      } else if (input.value !== prevProps.input.value) {
        this.setSelectedDate(new Date(input.value))
      }
    }
  }

  render() {
    const { currentLanguage, disableFuture = false, maxDate = null, minDate = null } = this.props

    const placeholderText = currentLanguage === "en" ? "dd.mm.yyyy" : currentLanguage === "ru" ? "дд.мм.гггг" : "zz.ll.aaaa"

    return (
      <DatePickerComponent
        selected={this.state.selectedDate}
        onChange={this.setSelectedDate}
        dateFormat={"DD.MM.YYYY"}
        disableFuture={disableFuture}
        maxDate={maxDate}
        minDate={minDate}
        customInput={
          <MaskedInput
            type="text"
            mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
          />
        }
        {...{ placeholderText }}
        {...this.props}
      />
    )
  }
}

const DatePickerTranslation = withTranslation(DatePicker)
export const DatePickerField = withFormField(DatePickerTranslation)

export default DatePickerTranslation
