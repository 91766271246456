import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import fetch from '../../components/shared/fetch'
import * as actionTypes from './action-types'
import { oops, fetchCompanies } from '../utils'

// ------------------------------------------
//
// ------------------------------------------
export const loadItems = props => {
  return (dispatch, getState) => {
    const state = getState()
    const { langId } = state.ui
    // assign defaults if props missing
    props = _.defaults(props, {
      pageIndex: 0,
      pageSize: 10,
      requestedAt: new Date(),
      includeDeleted: false,
      country: null,
      businessType: null,
      sortBy: "foundation",
    })

    // set 'loading'
    dispatch({ type: actionTypes.COMPANY_LIST_LOADING, ...props })
    // call api
    if (props.filterKey !== "verification" && props.filterKey !== "historyUsers" && props.filterKey !== "historyCompanies") {
      return fetchCompanies({ langId, ...props })
        .then(json => dispatch({ type: actionTypes.COMPANY_LIST_LOADED, data: json.d }))
        .catch(error => dispatch(oops(error, actionTypes.COMPANY_LIST_LOAD_ERROR)))
    }
  }
}
// ------------------------------------------
//
// ------------------------------------------
export const setParams = (scope, params) => {
  return dispatch => {
    dispatch({
      type: actionTypes.COMPANY_LIST_SET_PARAMS,
      payload: { scope, params },
    })
    dispatch(loadItems(params))
  }
}

// ------------------------------------------
//
// ------------------------------------------
export const getItem = ({ itemId }) => {
  return (dispatch, getState) => {
    const state = getState()
    const current = state.news.item

    if (itemId === current.itemId && current.loading) return

    dispatch({ type: actionTypes.COMPANY_ITEM_LOADING, requestedAt: new Date(), itemId })

    return fetch(`/company/${itemId}`)
      .then(json => dispatch({ type: actionTypes.COMPANY_ITEM_LOADED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.COMPANY_ITEM_LOAD_ERROR)))
  }
}
// // ------------------------------------------
// // set edit item
// // ------------------------------------------
// export const setEditingItem = (langId, item) => ({
//   type: actionTypes.COMPANY_SET_EDITING_ITEM,
//   langId,
//   item,
// })
// ------------------------------------------
//
// ------------------------------------------
export const save = values => {
  return dispatch => {
    dispatch({ type: actionTypes.COMPANY_SAVE_START, requestedAt: new Date() })
    return fetch('/company/save', {
      method: 'POST',
      body: JSON.stringify(values),
    })
      .then(json => dispatch({ type: actionTypes.COMPANY_SAVED, data: json.d }))
      .catch(error => dispatch(oops(error, actionTypes.COMPANY_SAVE_ERROR)))
  }
}
// ------------------------------------------
//
// ------------------------------------------
export const deleteItem = (itemId, cb) => {
  return dispatch => {
    dispatch({ type: actionTypes.COMPANY_DELETING, requestedAt: new Date(), itemId })
    return fetch(`/news/${itemId}`, { method: 'DELETE' })
      .then(json => {
        dispatch({ type: actionTypes.COMPANY_DELETED, data: json.d })
        cb && cb(itemId)
      })
      .catch(error => dispatch(oops(error, actionTypes.COMPANY_DELETE_ERROR)))
  }
}

export const showTutorialJoinCompanyPopup = () => {
  return dispatch => {
    dispatch({ type: actionTypes.COMPANY_TUTORIAL_POPUP })
  }
}

export const showUserCompanyInProcessContainer = (companyName, inCompanyState, companyId) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SHOW_USER_COMPANY_IN_PROCESS,
      payload: { companyName, inCompanyState, companyId }
    })
  }
}

export const companyChangeDateRegistration = (companyId, dateRegistration) => {
  return async dispatch => {
    return fetch('/company/changeDateRegistration', {
      method: 'POST',
      body: JSON.stringify({
        companyId,
        dateRegistration,
      }),
    }).then(() => {
      toastr.success(window.t('company.date.registration.change.success'))
    })
  }
}