import React, { Component, Fragment } from 'react'
import { match } from 'react-router'
import { Link } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'

import TooltipWrapper from '../../UI/popover-wrapper'
import fetch from '../../shared/fetch'
import { makeLinksActive } from '../../shared/utils'
import { canDo } from '../../../store/utils'

import './style.scss'

interface IGetAdminNoticeProps extends ComponentBaseProps, PermissionInjectProps {
  match: match<{ userId }>
}

interface IGetAdminNoticeState {
  noticeUserId: number
  noticeText: string
  noticeTitle: string
  noticeDateEnd: Dayjs
  userFound: boolean
}

export default class AdminNotice extends Component<IGetAdminNoticeProps, IGetAdminNoticeState> {
  constructor(props) {
    super(props)

    this.state = {
      noticeUserId: parseInt(props.match?.params['userId']) || props.userId,
      noticeText: '',
      noticeTitle: '',
      noticeDateEnd: dayjs(),
      userFound: false,
    }
  }

  componentDidMount() {
    this.checkUserAndRefresh()
  }

  checkUserAndRefresh = (userId?: number) => {
    fetch(`/user/checkUserExists?userId=${userId || this.state.noticeUserId}`).then(res => {
      if (res.ok) {
        this.refreshNotice(userId)
        this.setState({ userFound: true })
      }
    })
  }

  componentDidUpdate(oldProps: IGetAdminNoticeProps) {
    const { match, userId: profileUserId } = this.props
    const { userId = null } = match?.params
    const { userId: oldUserId = null } = oldProps.match?.params

    if ((userId || oldUserId) && parseInt(userId) !== parseInt(oldUserId)) {
      this.checkUserAndRefresh(userId || profileUserId)
    }
  }

  refreshNotice = (userId?: number) => {
    const { userId: profileUserId, permissions } = this.props
    const { noticeUserId } = this.state
    const isAdmin = canDo(permissions, 'admin')

    if (isAdmin || profileUserId === userId || profileUserId === noticeUserId) {
      fetch(`/user/getAdminNotice/${userId || noticeUserId}`).then(response => {
        const { data } = response
        data &&
          this.setState({
            noticeText: data.text,
            noticeTitle: data.title,
            noticeDateEnd: dayjs(data.datePosted).add(data.placementPeriod, 'day'),
            noticeUserId: userId || noticeUserId,
          })
      })
    }
  }

  onAgree = event => {
    event.preventDefault()
    this.setState({ noticeText: null })
    fetch('/user/deleteAdminNotice', {
      method: 'DELETE',
      body: JSON.stringify({
        userId: this.state.noticeUserId,
      }),
    })
  }

  render() {
    const { t, userId } = this.props
    const { noticeText, noticeTitle, noticeDateEnd, userFound, noticeUserId } = this.state
    const isUser = noticeUserId === userId

    if (!userFound || !noticeText) return ''

    const isTermless = noticeDateEnd.diff(dayjs(), 'day') > 1e5
    const dateMessage = (
      <Fragment>
        <div>
          {t('admin.notice.date.end', {
            date: isTermless
              ? t('termless').toLocaleLowerCase()
              : t('until') + noticeDateEnd.format(' DD/MM/YYYY'),
          })}
        </div>
        <Link to="/about-us/terms-of-use">{t('admin.notice.rules')}</Link>
      </Fragment>
    )

    return (
      <TooltipWrapper text={dateMessage} placement="left" hover>
        <div className={'admin-notice-panel'}>
          <h4>{noticeTitle}</h4>
          <div className="text">{makeLinksActive(noticeText)}</div>
          {isUser && (
            <button className="button button-block mt-sm" onClick={event => this.onAgree(event)}>
              {t('agree')}
            </button>
          )}
        </div>
      </TooltipWrapper>
    )
  }
}
